.navBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 90;
    background: none;
    width: 97%;
    padding: 1.5%;
    position: fixed;
    top: 0;
}
.logo {
    height: 3rem;
    cursor: pointer;
    backdrop-filter: blur(50px);
    background-color: rgb(14, 14, 14, 0.5);
}
.author {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
#navLinks {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 15px;
}
.navBarSubSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.activeLink,
.link {
    text-decoration: none;
    font-size: 1rem;
    color: rgba(240, 240, 240, 0.5);
    font-weight: 600;
    padding: 0.6rem;
    transition: 0.4s;
    margin: 0 1rem;
    text-transform:uppercase;
    background: none;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0);
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.activeMobileLink,
.mobileLink {
    text-decoration: none;
    font-size: 1rem;
    color: rgba(240, 240, 240, 0.5);
    font-weight: 600;
    transition: 0.4s;
    text-transform:uppercase;
    background: none;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.underline {
    position: absolute;
    width: 5.2rem;
    height: 2.7rem;
    border: 2px solid rgb(211, 155, 0);
    z-index: -1;
    border-radius: 14px;
}
.activeLink:hover,
.link:hover {
    color: rgb(211, 155, 0);
}
.activeMobileLink,
.activeLink {
    color: rgb(211, 155, 0);
}
.topButtonContainer {
    background-color: rgba(179, 179, 179, 0.5);
    backdrop-filter: blur(4px);
    position: fixed; 
    width: 9rem;
    height: 3rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0);
    text-transform: uppercase;
    transition: 0.2s;
    z-index: 80;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.05rem;
}
.capitalize {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(24, 24, 24, 0.8);
}
.topButtonContainer:hover {
    color: rgba(0, 0, 0, 1);
    background-color: rgb(211, 155, 0);
}
.socialContainer {
    height: 4rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.iconContainer {
    margin: 0.2rem;
    cursor: pointer;
    transition: 0.3s;
}
.iconContainer:hover {
    color: rgba(0, 0, 0, 1)
}
.icon {
    font-size: 1.3rem;
    margin: 0.5rem;
}
.hamburgherContainer{
    display: none;
}
#mobileNavLinks {
    display: none;
}
.bar {
    width: 35px;
    height: 3px;
    margin: 3px 0;
    border-radius: 2px;
    background-color: rgb(240, 240, 240);
}
@media only screen and (max-width: 825px) {
    .navBarContainer {
        padding: 3%;
        width: 94%;
    }
    .logo {
        height: 2.3rem;
    }
    #authorSection {
        display: block;
        background: none;
    }
    .author {
        font-size: 1.5rem;
    }
    #navLinks {
        display: none;
    }
    #mobileNavLinks {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: column;
        width: 100vw;
        height: 100vh;
        z-index: 80;
    }
    .mobileLinks {
        height: 80%;
        width: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 25%;
        padding-bottom: 25%;

    }
    .socialContaner {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 90%;
        padding: 5%;
        border-top: 1px solid rgba(240, 240, 240, 0.2);
    }
    .hamburgherContainer {
        display: block;
        display: flex;
        align-items: end;
        justify-content: center;
        flex-direction: column;
        height: 4rem;
        width: 4rem;
        cursor: pointer;
        z-index: 99;
        padding: 0 1vw;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .activeLink,
    .link {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .activeMobileLink,
    .mobileLink {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        user-select: none;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .icon {
        font-size: 1.5rem;
        color: rgb(240, 240, 240);
    }
    .navBarSubSection {
        background-color: rgb(14, 14, 14);
        border-radius: 0px;
        backdrop-filter: blur(0px);
    }
}