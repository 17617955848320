.formContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgba(14, 14, 14, 0.3);

    border-radius: 15px;
}
form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.formTitleContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 90%;
}
.formTitle {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.7rem;
}
.section,
.textAreaSextion {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: 1rem 0;
}
.input {
    width: 95%;
    padding: 0.5rem 0.4rem;
    border-radius: 2px;
    background-color: rgba(240, 240, 240, 0.2);
    border: 2px solid rgba(240, 240, 240, 0.7);
    transition: 0.3s;
    text-align: center;
    font-family: "Anton", sans-serif;
    font-size: 1.1rem;
    font-weight: 400;

}
.input:foc, .textArea:focus {
    border: 2px solid rgb(211, 155, 0);
}
#name {
    margin-right: 10%;
}
.textAreaSextion {
    width: 100%;
}
.textArea {
    width: 100%;
    height: 120px;
    padding: 12px 20px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
    resize: none;
    border-radius: 2px;
    background-color: rgba(240, 240, 240, 0.2);
    border: 2px solid rgba(240, 240, 240, 0.7);
    transition: 0.3s;
}
.label {
    text-align: justify;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.05rem;
    margin: 0.5rem 0;
}
.inline {
    display: flex;
    justify-content: space-evenly;
    align-items: space-between;
    width: 100%;
}
.formButtons {
    margin-top: 0.5rem;
    width: 100%;
}
.formButton,
.disabledFormButton {
    padding: 0.3rem;
    width: 100%;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 800;
    transition: 0.3s;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: rgb(25, 25, 25);
    transition: 0.3s;
    margin: 0 1rem 0 0;
    border: 2px solid rgb(240, 240, 240);
}
.formButton {
    cursor: pointer;
}
.formButton:hover {
    border-color:  rgb(211, 155, 0);
    background-color:  rgb(211, 155, 0);
    color: rgb(25, 25, 25);
    display: flex;
    align-items: center;
    justify-content: center;
}
.successMessage {
    color: rgba(240, 240, 240, 0.8);
    font-size: 1.2rem;
}
.successContainer {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.formIconContainer {
    padding: 4rem 0;
}
.successMessage {
    padding: 2rem 0;
}
@media only screen and (max-width: 768px) {
    .inline {
        flex-direction: column;
    }
    .section {
        width: 100%;
    }
    .input {
        text-align: left;
    }
    .textArea {
        padding: 0.5rem 0.4rem;
    }
}

