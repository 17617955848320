.VFXContainer {
    padding-top: 7%;
}
.videoPlayer {
    width: 100vw;
    height: auto;
}
.tilesContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
}
.tileContainer {
    width: 25vw;
    height: 25vw;
    cursor: pointer;
    background-position: center;
    background-size: contain;
    background-color: rgb(25, 25, 25);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.opacityFilter {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-position: center;
    background-size: cover;
}
.tileContainer {
    background-color: rgba(0, 0, 0, 0.1);
}

.cardContainer {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
}
.tileTitle {
    font-size: 1.8rem;
    color: rgb(240, 240, 240);
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.tileTitleContainer {
    padding: 2rem 2rem;
}
.bannerContainer {
    max-height: 30rem;
    overflow: hidden;
    position: relative;
}
.banner {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.videoPlayerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
}
.videoPlayer {
    width: 95%;
    height: auto;
}
.VFXTitle {
    font-size: 3rem;
    text-transform: uppercase;
    text-align: center;
    margin: 1.5rem;
    color: rgb(240, 240, 240);
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.bannerContainer {
    min-height: 20rem;
    display: flex;
    align-items: end;
    position: relative;
    top: 0;
    width: 100%;
}
.detailsSection {
    display: flex;
    padding: 5% 2.5% 10%;

}
.category {
    width: 30%;
}
.catList {
    color: rgba(240, 240, 240, 0.8);
}
.description {
    width: 65%;
}
.category,
.description {
    display: flex;
    flex-direction: column;
}
.descriptionTitle {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.descrBody {
    color: rgb(180,180,180);
}
.space {
    padding: 1rem 0;
    font-size: 2rem;
}
@media only screen and (max-width: 1000px) {
    .VFXContainer {
        padding-top: 10%;
    }
}

@media only screen and (max-width: 825px) {
    .tileContainer {
        width: 50vw;
        height: 50vw;
    }
    .videoPlayer {
        width: 100%;
    }
    .category,
    .description {
        width: 100%;
        margin: 2.5%;
    }
    .detailsSection {
        flex-direction: column;
    }
}
@media only screen and (max-width: 540px) {
    .VFXContainer {
        padding-top: 20%;
    }
    .tileContainer {
        width: 100vw;
        height: 100vw;
    }
}