.mainContainer {
    background-color: rgb(14, 14, 14);
}

.heroContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30rem;
}
.heroImage {
    max-width: 40%;
    height: auto;
    border-radius: 10px;
}
.descriptionSection {
    margin: 0 15vw;
}
p {
    margin-top: 4rem;
    font-size: 1.2rem;
    color: rgb(240, 240, 240);
}
.centered {
    margin-top: 6rem;
    text-align: center;
}

/* Switch */
.switchContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 10rem;
    height: 3rem;
    background-color:rgba(80, 80, 80, 0.6);
    border-radius: 15px;
    margin: 2rem 0;
    z-index: 1;
}
.tabContainer {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.tabText {
    font-size: 0.8rem;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    z-index: 3;
    transition: 0.3s;
}
.selection {
    height: 2.6rem;
    width: 4.6rem;
    position: absolute;
    background-color: rgb(211, 155, 0);
    border-radius: 12px;
    z-index: 2;
}

/* Contact card*/
.contactContainer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4rem 0;
    margin: 0 10%;
}


.flip-card {
    background-color: transparent;
    width: 80%;
    aspect-ratio: 16/10;
    perspective: 2000px;
    z-index: 4;
    border-radius: 15px;
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform-style: preserve-3d;
    border: 1px solid rgba(240, 240, 240, 0.6);
    transition: 0.8s;
    border-radius: 15px;
}
  

  
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 15px;
    background-position: center;
    overflow: hidden;
}
  
.flip-card-front {
    background-color: black;
    color: black;
    display: flex;
}

  .left, .right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 6%;
    background-color: rgba(0, 0, 0, 0.2);
}
.contactCardSocial {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}
.left {
    width: 55%;
    border-right: 1px solid rgba(240, 240, 240, 0.2);
    align-items: center;
}
.right {
    width: 45%;
    align-items: flex-start;
}
.contactCardIcon {
    font-size: 2rem;
    margin-right: 0.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialName {
    color: rgba(240, 240, 240, 0.6);
    font-size: 1.3rem;
}
  
.flip-card-back {
    background-color: rgb(0, 0, 0);
    color: white;
    transform: rotateY(180deg);
}

.contactCardLogo{
    height: 30%;
}
.contactCardAuthor {
    color:rgb(240, 240, 240);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 800;
    font-size: 1.2rem;
}
.contactCardSecondary {
    color: rgba(240, 240, 240, 0.6);
    font-weight: 600;
}
.selfDescrContainer {
    width: 100%;
    padding-top: 0rem;
    display: flex;
    flex-direction: column;
}
.selfDescTitle,
.selfDescContent {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.selfDescTitle {
    font-size: 2.8rem;
    text-transform: uppercase;
    padding-top: 3rem;
}
.selfDescContent {
    color: rgba(240, 240, 240, 0.6);
    letter-spacing: 0.05rem;
}

@media only screen and (max-width: 1041px) {
    .flip-card {
        width: 100%;
    }
}

@media only screen and (max-width: 825px) {
    .contactContainer {
        width: 90%;
        margin: 0 5%;
    }
    .switchContainer {
        z-index: 89;
    }
    .contactCardLogo {
        height: 30%;
    }
    .flip-card-front {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .left {
        border-right: 0px;
        border-bottom: 1px solid rgba(240, 240, 240, 0.6);
    }
    .right {
        width: 50%;
        display: flex;
        justify-content: center;
    }
    .left,
    .right {
        padding: 0;
        margin: 0;
        height: 50%;
        width: 100%;
    }
    .flip-card {
        aspect-ratio: 12/9;
    }
    .descriptionSection {
        margin: 0 1rem;
    }
    .heroContainer {
        min-height: 10rem;
    }
    .heroImage {
        max-width: 80%;
        height: auto;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .flip-card {
        width: 100%;
        aspect-ratio: 1/1;
    }
    .descriptionSection {
        margin: 0 1rem;
    }
    .heroContainer {
        min-height: 10rem;
    }
    .heroImage {
        max-width: 95%;
        height: auto;
        border-radius: 10px;
    }
}
@media only screen and (max-width: 550px) {
    .flip-card {
        aspect-ratio: 9/15;
    }
    .right {
        width: 50%;
    }
}


