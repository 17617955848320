.MoviePostersContainer {
    display: flex;
    flex-wrap: wrap;
}
.posterContainer {
    width: 24.7vw;
    min-height: 37vw;
    transition: 0.5s;
    background-position: center;
    background-size: cover;
    background-repeat: none;
}
.hoverContent {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
}
.posterTitle {
    font-size: 2rem;
    text-align: center;
    width: 100%;
}
.bottomTile {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.hoverContent {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    transition: 0.5s;
    padding: 5%;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.inlinePoster {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 1rem;
}
.platform {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    color: rgb(240, 240, 240);
}
.secondary {
    color: rgb(200, 200, 200);
    letter-spacing: 0.1rem;
    font-size: 0.8rem;
}

@media only screen and (max-width: 768px) {
    .posterContainer {
        width: 100vw;
        height: 148vw;
    }
}