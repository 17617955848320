.loaderContainer {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(25, 25, 25,0.3);
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loaderLogo {
    width: 25vw;
    height: 25vw;
}