.trailerContainer {
    height: 70vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.bannerVideo {
    position: relative;
    top: 0;
    height: 75vh;
    width: 100%;
    object-fit: cover;
}
.latestContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    position: absolute;
    z-index: 10;
    display: flex;
    cursor: pointer;
}
.latestText {
    font-size: 1.2rem;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.1rem;
    margin-bottom: 10px;
}
.presentationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
}
.presentationTitle {
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: -0.05rem;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.presentationSubtitle {
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: -0.05rem;
    color: rgb(180,180,180);
}
.showMoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(24, 24, 24);
    cursor: pointer;
    transition: 0.3s;
}
.longButtonText {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: rgb(240,240,240);
}
.showMoreContainer:hover {
    background-color: rgb(16, 16, 16);
}
.longButtonText:hover {
    color:  rgb(211, 155, 0);
}

@media only screen and (max-width: 825px) {
    .trailerContainer,
    .bannerVideo {
        height: 100vh;
    }
    .presentationTitle {
        font-size: 2.5rem;
        display: flex;
    }
    .presentationSubtitle {
        font-size: 1.2rem;
    }
}
@media only screen and (max-width: 768px) {
    .presentationContainer {
        text-align: center;
        margin: 0 4rem;
        padding-top: 8rem;
    }
    .presentationTitle {
        margin-bottom: 0.5rem;
    }
    .latestContainer {
        display: flex;
        padding-bottom: 6rem;
    }
}