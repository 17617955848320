.footerContainer {
    background-color: rgb(25, 25, 25);
    min-height: 75vh;
    margin-top: 6rem;
}
.buttonContainer {
    border: 4px solid rgb(240, 240, 240);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 4rem;
    transition: 0.3s;
}
.buttonText {
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 800;
    transition: 0.3s;
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.buttonContainer:hover {
    border-color:  rgb(211, 155, 0);
    background-color:  rgb(211, 155, 0);
}
.buttonText:hover {
    color: rgb(14, 14, 14);
}
.socialFooterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
}
.footerIcon {
    font-size: 2rem;
    color: rgb(240, 240, 240);
    padding: 1.5rem;
}