.galleryContainer {
    margin-bottom: 1rem; 
    padding-top: 7%;
    display: flex;
    flex-wrap: wrap;
}
.imageContainer {
    overflow: hidden;
    width: 24.7vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(79, 79, 79);
    min-height: 10rem;    
}
.galleryPost {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
}
.ExpandedImageContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0);
    z-index: 91;
    display: flex;
    align-items: center;
    justify-content: center;
}
.expandedImage {
    max-width: 100%;
    max-height: 100%;
    z-index: 93;
}
.closeBtn {
    position: fixed;
    top: 1rem;
    right: 1rem;
    height: 2rem;
    width: 2rem;
    color: rgba(240,240,240,0.5);
    cursor: pointer;
    transition: 0.3s;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    backdrop-filter: blue(1px);
}
.closeBtn:hover {
    color: rgba(240,240,240,1)
}
.closeIcon {
    font-size: 2rem;
    background: none;
}
.fixedLeft,
.fixedRight {
    height: 100vh;
    position: fixed;
    z-index: 95;
    display: flex;
    align-items: center;
    margin: 1rem;
    background: none;
}
.fixedLeft {
    left: 0;
    justify-content: flex-start;
}
.fixedRight {
    right: 0;
    justify-content: flex-end;
}
.controlIcon {
    font-size: 3rem;
    color: rgba(240,240,240,0.5);
    cursor: pointer;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    backdrop-filter: blur(1px);
}
.controlIcon:hover {
    color: rgba(240,240,240,1)
}
.descriptionContainer {
    display: flex;
    flex-direction: column;
    width: 96%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    box-sizing: border-box;
    padding: 1rem;
    background-color: rgba(100,100,100,0.4);
    backdrop-filter: blur(3px);
    border-radius: 15px;
    margin: 2%;
}
.imageTitle {
    font-size: 1.2rem;
    color: rgba(240,240,240,1);
    padding: 0.1rem;
}
.imageType,
.imageDescription {
    color: rgba(240,240,240,0.8);
}
.imageDescription {
    font-size: 0.8rem;
    padding-top: 0.3rem;
}


@media only screen and (max-width: 1000px) {
    .galleryContainer {
        padding-top: 10%;
    }
}
@media only screen and (max-width: 768px) {
    .galleryContainer {
        margin-bottom: 1rem; 
        padding-top: 15%;
    }
    .fixedLeft,
    .fixedRight {
        display: none;
    }
    .ExpandedImageContainer {
        z-index: 93;
    }
    .imageContainer {
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 540px) {
    .galleryContainer {
        padding-top: 20%;
    }
}